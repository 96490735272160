import { get_auth_header, service_api_service } from '@/services/service_api/index'
import { DateTime }                             from 'luxon'

export const get_live_link_pro_data = async ({ esiid, year, month }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/service_locations/${esiid}/live_link/pro`, {
      params: {
        year,
        month
      },
      headers
    })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const get_live_link_pro_months = async ({ esiid }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/service_locations/${esiid}/live_link/pro/months`, { headers })
    return data.map(x => ({
      ...x,
      label: `${x.month_abbr} ${x.year} (${DateTime.fromISO(x.start).toFormat('MMM d, yyyy')} - ${DateTime.fromISO(x.end).toFormat('MMM d, yyyy')})`
    }))
  }
  catch (err) {
    console.error(err)
  }
}

export const get_service_location_by_esiid = async ({ esiid }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/service_locations/${esiid}`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const get_all_service_locations_by_user = async () => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/service_locations`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const get_service_locations_that_have_orders = async () => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/orders/service_locations`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const add_service_location_by_esiid = async ({ esiid }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.post(`/api/service_locations`,
      { esiid },
      { headers }
    )
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const delete_service_location_by_esiid = async ({ esiid }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.delete(`/api/service_locations/${esiid}`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const delete_all_service_locations_for_user = async () => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.delete(`/api/service_locations`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const update_service_location = async ({ location_id, update_request }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.patch(`/api/service_locations/${location_id}`, update_request, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}

export const get_best_plans = async ({ esiid }) => {
  try {
    const headers = await get_auth_header()
    const { data } = await service_api_service.get(`/api/service_locations/${esiid}/best_plans`, { headers })
    return data
  }
  catch (err) {
    console.error(err)
  }
}
