import { createRouter, createWebHistory } from 'vue-router'
import Auth                               from '@/composables/Auth'
import CallbackView                       from '@/views/CallbackView.vue'
import { supabase }                       from '@/supabase'
import { nextTick }                       from 'vue'


const { auth_guard, is_authenticated, user, agreed_to_terms, has_been_processed, email_verification_guard } = Auth()

const shop_usage_auth_guard = (to, from, next) => {
  if (!is_authenticated.value) {
    next()
  }
  else {
    router.push('/shop_usage/accept_terms')
  }
}
const renewal_auth_guard = (to, from, next) => {
  next()
}

const check_for_auth_guard = async (to, from, next) => {

  const cookies = document.cookie.split(/\s*;\s*/).map(cookie => cookie.split('='))
  const accessTokenCookie = cookies.find(x => x[0] === 'my-access-token')
  const refreshTokenCookie = cookies.find(x => x[0] === 'my-refresh-token')

  if (!is_authenticated.value) {
    if (accessTokenCookie && refreshTokenCookie) {
      await supabase.auth.setSession({
        access_token: accessTokenCookie[1],
        refresh_token: refreshTokenCookie[1]
      })
    }
  }
  // else {
  //   if (!accessTokenCookie || !refreshTokenCookie) {
  //     console.log('signing out')
  //     await supabase.auth.signOut()
  //   }
  // }

  next()
}

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    else if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ el: to.hash, top: 70, behavior: 'smooth' })
        }, 500)
      })
    }
    else {
      return { top: 0 }
    }
  },
  routes: [
    {
      // path: '/notifications',
      // component: () => import('@/layouts/MainLayout.vue'),
      // children: {
      path: '/opt_out',
      component: () => import('@/views/notifications/OptOut.vue'),
      props: route => ({ ...route.query }),
      meta: { title: 'Unsubscribe' }
      // }
    },
    {
      path: '/shop_usage',
      component: () => import('@/layouts/ShopUsageLayout.vue'),
      children: [
        {
          path: '',
          name: 'shop_usage_default',
          component: () => import('@/views/shop_usage/SignUpView.vue'),
          beforeEnter: shop_usage_auth_guard,
          meta: { title: 'Sign Up' }
        },
        {
          path: 'login',
          name: 'shop_usage_login',
          component: () => import('@/views/shop_usage/LoginView.vue'),
          beforeEnter: shop_usage_auth_guard,
          meta: { title: 'Login' }
        },
        {
          path: 'signup',
          name: 'shop_usage_signup',
          component: () => import('@/views/shop_usage/SignUpView.vue'),
          beforeEnter: shop_usage_auth_guard,
          meta: { title: 'Sign Up' }
        },
        {
          path: 'password/reset',
          name: 'shop_usage_password_reset',
          component: () => import('@/views/shop_usage/PasswordResetView.vue'),
          meta: { title: 'Password Reset' },
          props: route => ({ email_address: route.query.email_address })
        },
        {
          path: 'verify',
          name: 'shop_usage_verify',
          component: () => import('@/views/shop_usage/AccountVerificationView.vue'),
          beforeEnter: shop_usage_auth_guard,
          meta: { title: 'Verify your account' }
        },
        {
          path: 'accept_terms',
          name: 'shop_usage_accept_terms',
          component: () => import('@/views/shop_usage/AcceptTermsView.vue'),
          beforeEnter: (to, from, next) => {
            if (is_authenticated.value) {
              if (agreed_to_terms.value) {
                router.push('/shop_usage/process')
              }
              else {
                next()
              }
            }
            else {
              router.push('/shop_usage/login')
            }
          },
          meta: { title: 'Accept Terms and Conditions' }
        },
        {
          path: 'process',
          name: 'shop_usage_process',
          component: () => import('@/views/shop_usage/LoadLocationFromOrdersView.vue'),
          beforeEnter: async (to, from, next) => {
            if (is_authenticated.value) {
              if (user.value.user_metadata.processed_orders) {
                next({ path: '/shop_usage/select_location' })
              }
              else {
                next()
              }
            }
            else {
              next({ path: '/shop_usage/login' })
            }
          },
          meta: { title: 'Load locations from orders' }
        },
        {
          path: 'select_location',
          name: 'shop_usage_select_location',
          component: () => import('@/views/shop_usage/SelectLocationView.vue'),
          beforeEnter: async (to, from, next) => {
            if (is_authenticated.value) {
              next()
            }
            else {
              next({ path: '/shop_usage/login' })
            }
          },
          meta: { title: 'Load locations from orders' }
        },
        {
          path: 'add_location',
          name: 'shop_usage_add_location',
          component: () => import('@/views/shop_usage/AddServiceLocationView.vue'),
          beforeEnter: async (to, from, next) => {
            if (is_authenticated.value) {
              next()
            }
            else {
              next({ path: '/shop_usage/login' })
            }
          },
          meta: { title: 'Add new location' }
        },
        {
          path: 'live_link',
          name: 'enable_live_link',
          component: () => import('@/views/shop_usage/EnableLiveLinkView.vue'),
          beforeEnter: async (to, from, next) => {
            if (is_authenticated.value) {
              next()
            }
            else {
              next({ path: '/shop_usage/login' })
            }
          },
          meta: { title: 'Enable Live Link&trade;' }
        },
        {
          path: 'summary/:id',
          name: 'shop_usage_summary',
          component: () => import('@/views/shop_usage/SummaryView.vue'),
          beforeEnter: async (to, from, next) => {
            if (is_authenticated.value) {
              next()
            }
            else {
              next({ path: '/shop_usage/login' })
            }
          },
          meta: { title: 'Location Summary' },
          props: true
        }
      ]
    },

    {
      path: '/renewal',
      component: () => import('@/layouts/ShopUsageLayout.vue'),
      children: [
        {
          path: '',
          name: 'renewal_default',
          component: () => import('@/views/renewal/SignUpView.vue'),
          beforeEnter: renewal_auth_guard,
          meta: { title: 'Sign Up' }
        },
        {
          path: 'login',
          name: 'renewal_login',
          component: () => import('@/views/renewal/LoginView.vue'),
          beforeEnter: renewal_auth_guard,
          meta: { title: 'Login' }
        },
        {
          path: 'signup',
          name: 'renewal_signup',
          component: () => import('@/views/renewal/SignUpView.vue'),
          beforeEnter: renewal_auth_guard,
          meta: { title: 'Sign Up' }
        },
        {
          path: 'password/reset',
          name: 'renewal_password_reset',
          component: () => import('@/views/renewal/PasswordResetView.vue'),
          meta: { title: 'Password Reset' },
          props: route => ({ email_address: route.query.email_address })
        },
        {
          path: 'account/verify',
          name: 'renewal_verify',
          component: () => import('@/views/renewal/AccountVerificationView.vue'),
          beforeEnter: renewal_auth_guard,
          props: route => ({ email: route.query.email }),
          meta: { title: 'Verify your account' }
        },
        {
          path: 'accept_terms',
          name: 'renewal_accept_terms',
          component: () => import('@/views/renewal/AcceptTermsView.vue'),
          beforeEnter: (to, from, next) => {
            if (is_authenticated.value) {
              if (agreed_to_terms.value) {
                router.push('/renewal/process')
              }
              else {
                next()
              }
            }
            else {
              router.push('/renewal/login')
            }
          },
          meta: { title: 'Accept Terms and Conditions' }
        },
        {
          path: 'process',
          name: 'renewal_process',
          component: () => import('@/views/renewal/ProcessView.vue'),
          beforeEnter: async (to, from, next) => {
            if (is_authenticated.value) {
              if (agreed_to_terms.value) {
                if (!has_been_processed.value) {
                  next()
                }
                else {
                  router.push('/')
                }
              }
              else {
                router.push('/renewal/accept_terms')
              }
            }
            else {
              router.push('/renewal/login')
            }

          },
          meta: { title: 'Load locations from orders' }
        }
      ]
    },

    {
      path: '/',
      component: () => import('@/layouts/MainLayout.vue'),
      beforeEnter: auth_guard,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/views/HomeView.vue'),
          meta: { title: 'Dashboard' }
        },

        // Orders
        {
          path: 'orders',
          name: 'Orders',
          component: () => import('@/views/orders/MyOrders.vue'),
          beforeEnter: auth_guard,
          meta: { title: 'My Orders' }
        },
        {
          path: 'orders/:id',
          name: 'Order Summary',
          component: () => import('@/views/orders/OrderSummary.vue'),
          meta: { title: 'Order Summary' },
          props: true
        },
        {
          path: 'orders/:id/renewal_opt_out',
          name: 'Order Renewal Summary',
          component: () => import('@/views/orders/OrderSummary.vue'),
          beforeEnter: auth_guard,
          meta: { title: 'Order Renewal Summary' },
          props: r => ({ id: r.params.id, show_renewal_opt_out: true })
        },

        // Service Locations
        {
          path: 'locations',
          name: 'Locations',
          component: () => import('@/views/service_locations/MyServiceLocations.vue'),
          meta: { title: 'My Locations' },
          props: true
        },
        {
          path: 'locations/:id',
          name: 'Location Summary',
          component: () => import('@/views/service_locations/ServiceLocationView.vue'),
          meta: { title: 'Location Summary' },
          props: true
        },
        {
          path: 'locations/:id/connection_manager',
          name: 'Connection Manager',
          component: () => import('@/views/service_locations/ConnectionManager.vue'),
          meta: { title: 'Connection Manager' },
          props: true
        },
        {
          path: 'locations/add',
          name: 'Add Service Location',
          component: () => import('@/views/service_locations/AddServiceLocation.vue'),
          meta: { title: 'Add Service Location' }
        },
        {
          path: 'shop',
          name: 'shop',
          component: () => import('@/views/ShopView.vue'),
          meta: { title: 'Shop' }
        },

        // User Section
        {
          path: 'account',
          name: 'My Account',
          component: () => import('@/views/user/MyAccount.vue'),
          meta: { title: 'My Account' }
        },

        // Notifications
        {
          path: 'notifications',
          name: 'Notifications',
          component: () => import('@/views/notifications/NotificationsView.vue'),
          meta: { title: 'Notifications' }
        }
      ]
    },

    // Auth
    {
      path: '/',
      component: () => import('@/layouts/AuthLayout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/auth/LoginView.vue'),
          meta: { title: 'Log In' }
        },
        {
          path: 'password/reset',
          name: 'password_reset',
          component: () => import('@/views/auth/PasswordResetView.vue'),
          meta: { title: 'Password Reset' },
          props: route => ({ email_address: route.query.email_address })
        },
        {
          path: 'account/verify',
          name: 'account_verification',
          component: () => import('@/views/auth/AccountVerificationView.vue'),
          beforeEnter: email_verification_guard,
          meta: { title: 'Account Verification' },
          props: route => ({ email: route.query.email })
        },
        {
          path: 'signup',
          name: 'signup',
          component: () => import('@/views/auth/SignUpView.vue'),
          meta: { title: 'Sign Up' }
        },
        {
          path: 'accept_terms',
          name: 'accept_terms',
          component: () => import('@/views/auth/AcceptTermsView.vue'),
          beforeEnter: (to, from, next) => {
            if (is_authenticated.value) {
              if (agreed_to_terms.value) {
                router.push('/')
              }
              else {
                next()
              }
            }
            else {
              router.push('/login')
            }
          },
          meta: { title: 'Accept Terms and Conditions' }
        },
        {
          path: 'process',
          name: 'process',
          component: () => import('@/views/auth/ProcessView.vue'),
          beforeEnter: (to, from, next) => {
            if (is_authenticated.value) {
              if (agreed_to_terms.value) {
                if (!has_been_processed.value) {
                  next()
                }
                else {
                  router.push('/')
                }
              }
              else {
                router.push('/accept_terms')
              }
            }
            else {
              router.push('/login')
            }
          },
          meta: { title: 'Accept Terms and Conditions' }
        },
        {
          path: 'add_location',
          name: 'add_location',
          component: () => import('@/views/auth/AddServiceLocation.vue'),
          beforeEnter: (to, from, next) => {
            if (is_authenticated.value) {
              if (agreed_to_terms.value) {
                if (has_been_processed.value) {
                  next()
                }
                else {
                  router.push('/process')
                }
              }
              else {
                router.push('/accept_terms')
              }
            }
            else {
              router.push('/login')
            }
          },
          meta: { title: 'Accept Terms and Conditions' }
        }
      ]
    },

    {
      path: '/callback',
      name: 'callback',
      component: CallbackView,
      meta: { title: 'callback' }
    },

    // Catch all
    {
      path: '/:catchAll(.*)*',
      component: () => import('@/views/ErrorNotFound.vue')
    }
  ],
  linkActiveClass: 'active'
})

router.beforeEach(check_for_auth_guard)

const DEFAULT_TITLE = 'ComparePower Portal'
router.afterEach((to, from) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})
export default router
